ion-button.selected {
  background-color: var(--ion-color-secondary);
  border: 1px solid var(--ion-color-primary);
  border-radius: 0.5em;
}

div.toolbar-container {
  padding: 0 0 0 0;
  margin: 0 0 0 0;
}
