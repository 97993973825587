ion-split-pane {
  --side-max-width: 300px;
  --border: 0px;
}

ion-card.plain {
  margin: 0.5em 0.5em 0.5em 0.5em;
  box-shadow: none;
}

.pricing-feedback-modal .modal-wrapper {
  min-height: 100px;
  height: auto;
}

// prime adjusts everything based on a scale
html {
  font-size: 14px;
}

// hide those pesky spinners on input field with type number
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

// customize modal
.rounded-modal {
  //--backdrop-opacity: 1;
  //backdrop-filter: blur(3px);

  //&::part(backdrop) {
  //  background: #000;
  //}

  //height: 100px;
  height: auto;

  &::part(handle) {
    background: var(--ion-color-primary);
    width: 150px;
    height: 6px;
  }

  &::part(content) {
    border-radius: 20px; // Here the border radius
    box-shadow: 0px 0px 20px 5px rgb(0 0 0 / 32%);
  }
}

.export-modal {
  --height: 23em;
}

.worksheet-modal {
  --height: 33em;
}

.new-sheet-modal {
  --width: 80%;
  --min-height: auto;
}
